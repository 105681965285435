@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

.section-header {
  text-align: center;
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  .section-header {
    margin-bottom: 50px;
  }
}
.section-header h2 {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 62px;
}
@media (max-width: 767px) {
  .section-header h2 {
    font-size: 42px;
  }
}
@media (max-width: 425px) {
  .section-header h2 {
    max-width: 330px;
    line-height: 1;
    margin: 0 auto;
  }
}

header#home {
  padding: 40px 20px;
}
@media (max-width: 425px) {
  header#home a.navbar-brand {
    max-width: 40%;
  }
  header#home a.navbar-brand img {
    width: 100%;
    height: 100%;
  }
}

#hero {
  margin-top: 50px;
}
#hero img.profile-pic {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
  max-width: 432px;
}
@media (max-width: 991px) {
  #hero img.profile-pic {
    width: 72vw;
  }
}
#hero .hello-world {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 104px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em;
}
#hero .hello-world h1 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
@media (max-width: 1250px) {
  #hero .hello-world {
    font-size: 80px;
  }
}
@media (max-width: 991px) {
  #hero .hello-world {
    max-width: 430px;
    margin: 30px auto 0 auto;
  }
}
@media (max-width: 767px) {
  #hero .hello-world {
    max-width: 460px;
    margin: 30px auto 0 90px;
    font-size: 70px;
  }
}
@media (max-width: 600px) {
  #hero .hello-world {
    font-size: 42px;
    max-width: 221px;
    margin: 20px auto 0 auto;
  }
}
#hero .about-me {
  font-weight: 200;
  font-size: 36px;
  max-width: 800px;
  margin: 100px auto 100px auto;
}
#hero .about-me strong {
  font-weight: 900;
}
#hero .about-container {
  background-color: #000000;
  color: #fff;
  margin-top: 140px;
}
@media (max-width: 767px) {
  #hero .about-container {
    margin-top: 90px;
  }
  #hero .about-container .about-me {
    margin: 120px auto;
    max-width: 620px;
    font-size: 30px;
    padding: 0 15px;
  }
}
@media (max-width: 767px) and (max-width: 600px) {
  #hero .about-container .about-me {
    font-size: 25px;
  }
}

#about .intro {
  margin: 120px auto 120px auto;
  max-width: 800px;
  font-size: 2rem;
  font-weight: 200;
}
#about .intro strong {
  font-weight: 900;
}
@media (max-width: 991px) {
  #about .intro {
    margin: 120px auto 120px auto;
  }
}
@media (max-width: 576px) {
  #about .intro {
    padding: 0 25px;
  }
}
#about .experience-list-container {
  background-color: #000000;
  color: #ffffff;
}
@media (max-width: 991px) {
  #about .experience-list-container .experience-list {
    font-size: 18px;
    max-width: 610px;
    margin: 80px auto;
  }
}
#about .experience-list-container ul.experience-list {
  max-width: 830px;
  margin: 120px auto 120px auto;
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 991px) {
  #about .experience-list-container ul.experience-list {
    color: #ffffff;
    font-size: 18px;
    max-width: 610px;
    margin: 120px auto 120px auto;
  }
}
@media (max-width: 767px) {
  #about .experience-list-container ul.experience-list {
    font-size: 16px;
    max-width: 530px;
    padding: 0 45px;
  }
}
#about .experience-list-container ul.experience-list li {
  margin-bottom: 20px;
}

nav.navbar .navbar-brand img {
  width: 100%;
  height: 100%;
}
nav.navbar .navbar-toggler {
  border-color: transparent;
  box-shadow: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}
nav.navbar .navbar-toggler img.menu-hamburger,
nav.navbar .navbar-toggler img.menu-close {
  height: 100%;
  width: 100%;
}
nav.navbar .navbar-toggler img.menu-hamburger {
  display: none;
}
nav.navbar .navbar-toggler.collapsed img.menu-hamburger {
  display: block;
}
nav.navbar .navbar-toggler.collapsed img.menu-close {
  display: none;
}
nav.navbar .navbar-nav {
  width: 100%;
}
@media (max-width: 991px) {
  nav.navbar .navbar-nav {
    text-align: center;
    margin: 50px 0;
  }
}
nav.navbar .navbar-nav a.nav-link {
  font-size: 21px;
  color: #000000;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 22px;
}
@media (max-width: 1440px) {
  nav.navbar .navbar-nav a.nav-link {
    font-size: 16px;
    margin: 0 15px;
  }
}
@media (max-width: 1150px) {
  nav.navbar .navbar-nav a.nav-link {
    font-size: 14px;
    margin: 0 10px;
  }
}
@media (max-width: 991px) {
  nav.navbar .navbar-nav a.nav-link {
    font-size: 40px;
    padding: 10px 0;
    font-weight: 900;
    margin: 0 -30px 0 -30px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 767px) {
  nav.navbar .navbar-nav a.nav-link {
    font-size: 30px;
  }
}
@media (max-width: 425px) {
  nav.navbar .navbar-nav a.nav-link {
    font-size: 25px;
  }
}

#skills {
  padding: 120px 0 0 0;
}
#skills .skills-container {
  margin-bottom: 130px;
}
@media (max-width: 1440px) {
  #skills .skills-container .skill-icons-wrap {
    -webkit-transform: scale(70%, 70%);
    transform: scale(70%, 70%);
  }
}
@media (max-width: 991px) {
  #skills .skills-container .skill-icons-wrap {
    -webkit-transform: none;
    transform: none;
  }
}
#skills .skills-container .skill-icons-wrap .icon-row-1 {
  display: flex;
  justify-content: space-between;
  max-width: 920px;
  margin: 0 auto 40px auto;
}
@media (max-width: 991px) {
  #skills .skills-container .skill-icons-wrap .icon-row-1 {
    margin-bottom: 0;
  }
}
#skills .skills-container .skill-icons-wrap .icon-row-2 {
  display: flex;
  justify-content: space-between;
  max-width: 850px;
  margin: 0 auto;
  align-items: flex-start;
}
#skills .skills-container .skill-icons-wrap .icon-row-2 > .icon-wrap:nth-child(3) img {
  margin-top: 6px;
}
#skills .skills-container .skill-icons-wrap .icon-row-2 > .icon-wrap:nth-child(5) img {
  margin-top: 6px;
}
#skills .skills-container .skill-icons-wrap .icon-row-3 {
  display: flex;
  justify-content: space-between;
  max-width: 290px;
  margin: 0 auto;
  align-items: flex-end;
}
#skills .skills-container .skill-icons-wrap .icon-row-3 .icon-WEBPACK {
  max-width: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#skills .skills-container .skill-icons-wrap .icon-row-1 .icon-wrap img,
#skills .skills-container .skill-icons-wrap .icon-row-2 .icon-wrap img {
  width: auto;
  height: auto;
}
@media (max-width: 991px) {
  #skills .skills-container .skill-icons-wrap .icon-row-1,
#skills .skills-container .skill-icons-wrap .icon-row-2 {
    flex-wrap: wrap;
    max-width: 320px;
  }
  #skills .skills-container .skill-icons-wrap .icon-row-1 .icon-wrap,
#skills .skills-container .skill-icons-wrap .icon-row-2 .icon-wrap {
    width: 50%;
    justify-content: center;
    display: flex;
    margin-bottom: 40px;
  }
}
#skills .other-skills-container {
  background-color: #000000;
  color: #ffffff;
  padding-top: 80px;
  padding-top: 120px;
  padding-bottom: 120px;
}
#skills .other-skills-container .section-header {
  margin-bottom: 55px;
}
#skills .other-skills-container .section-header h2 {
  font-size: 42px;
  font-style: italic;
}
@media (max-width: 767px) {
  #skills .other-skills-container .section-header h2 {
    font-size: 32px;
  }
}
#skills .other-skills-container .other-skills-list {
  column-count: 4;
  max-width: 950px;
  margin: 0 auto;
  font-weight: 800;
  font-size: 18px;
}
@media (max-width: 991px) {
  #skills .other-skills-container .other-skills-list {
    column-count: 2;
    max-width: 400px;
  }
}
@media (max-width: 550px) {
  #skills .other-skills-container .other-skills-list {
    font-size: 16px;
  }
}
@media (max-width: 375px) {
  #skills .other-skills-container .other-skills-list {
    font-size: 12px;
    max-width: 270px;
  }
}
#skills .other-skills-container .other-skills-list .other-skill {
  margin-bottom: 10px;
}

#experience {
  padding: 120px 0;
}
#experience .section-header {
  margin-bottom: 100px;
}
#experience .experience-row {
  justify-content: center;
}
@media (max-width: 991px) {
  #experience .experience-row .job-column {
    margin-bottom: 60px;
  }
}
#experience .experience-row .job-column .job-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#experience .experience-row .job-column .job-wrap.Acxiom .logo-wrap {
  max-width: 200px;
  margin-bottom: 30px;
}
#experience .experience-row .job-column .job-wrap.Mindutopia .logo-wrap {
  max-width: 165px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  #experience .experience-row .job-column .job-wrap.Bevelwise {
    margin-top: -31px;
  }
}
#experience .experience-row .job-column .job-wrap.Bevelwise .logo-wrap {
  max-width: 111px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  #experience .experience-row .job-column .job-wrap.Diocesan {
    margin-top: 50px;
  }
}
#experience .experience-row .job-column .job-wrap.Diocesan .logo-wrap {
  max-width: 201px;
  margin-bottom: 19px;
}
#experience .experience-row .job-column .job-wrap .logo-wrap {
  width: 100%;
}
#experience .experience-row .job-column .job-wrap .logo-wrap img {
  width: 100%;
  height: 100%;
}
#experience .experience-row .job-column .job-wrap .work-dates {
  font-size: 18px;
  font-weight: 300;
}
#experience .experience-row .job-column .job-wrap .job-title {
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 20px;
}
#experience .experience-row .job-column .job-wrap ul.job-info-list {
  max-width: 320px;
  font-size: 14px;
}

#education {
  background: #000000;
  color: #ffffff;
  padding: 130px 0;
}
#education .school-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#education .school-wrap .logo-wrap {
  max-width: 339px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  #education .school-wrap .logo-wrap {
    max-width: 200px;
  }
}
#education .school-wrap .logo-wrap img {
  width: 100%;
  height: 100%;
}
#education .school-wrap .degree-name {
  font-weight: 900;
  font-size: 26px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  #education .school-wrap .degree-name {
    max-width: 300px;
    text-align: center;
    font-size: 20px;
  }
}
#education .school-wrap .school-year {
  font-size: 26px;
  font-style: italic;
  font-weight: 200;
}
@media (max-width: 767px) {
  #education .school-wrap .school-year {
    font-size: 22px;
  }
}

#projects {
  padding: 120px 0;
}
#projects .projects-container .sub-header {
  font-size: 20px;
  font-style: italic;
}
#projects .projects-container .projects-row .projects-column .projects-wrap {
  margin-bottom: 70px;
}
@media (max-width: 767px) {
  #projects .projects-container .projects-row .projects-column .projects-wrap {
    margin-left: 25px;
    margin-right: 25px;
  }
}
#projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap {
  margin-bottom: 20px;
}
#projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap img {
  width: 100%;
  height: 100%;
}
#projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a {
  position: relative;
  display: block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}
#projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a::before, #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a::after {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
@media (max-width: 991px) {
  #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a::before, #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a::after {
    display: none !important;
  }
}
#projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a::before {
  content: "Visit";
  position: absolute;
  z-index: 10;
  color: #fff;
  top: 50%;
  text-align: center;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 900;
  background-color: #000;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
#projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}
#projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a:hover::after, #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a:hover::before, #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a:focus::after, #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a:focus::before, #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a:active::after, #projects .projects-container .projects-row .projects-column .projects-wrap .image-wrap a:active::before {
  display: flex;
  visibility: visible;
  opacity: 1;
}
#projects .projects-container .projects-row .projects-column .projects-wrap .project-title {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.4em;
}
#projects .projects-container .projects-row .projects-column .projects-wrap .project-title a {
  color: #000000;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}
#projects .projects-container .projects-row .projects-column .projects-wrap img.center-for-cd {
  height: 280px;
  object-fit: cover;
  object-position: left;
}

footer {
  background-color: #F4F4F4;
  padding: 130px 0 80px 0;
}
footer form.contact-form {
  max-width: 520px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  footer form.contact-form {
    padding: 0 25px;
  }
}
footer form.contact-form .form-control {
  border-radius: 0;
  border-color: #000000;
  border-width: 3px;
}
footer form.contact-form .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.1);
}
footer form.contact-form button[type=submit] {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
}
footer form.contact-form button[type=submit]:hover, footer form.contact-form button[type=submit]:focus, footer form.contact-form button[type=submit]:active {
  background-color: #434343;
}
footer .contact-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 160px auto 160px auto;
}
@media (max-width: 1440px) {
  footer .contact-wrap {
    max-width: 800px;
  }
}
@media (max-width: 991px) {
  footer .contact-wrap {
    flex-direction: column;
  }
  footer .contact-wrap a img {
    margin-bottom: 20px;
    height: 100%;
  }
}
footer .contact-wrap a {
  color: #000000;
  text-decoration: none;
  font-size: 28px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 1440px) {
  footer .contact-wrap a {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  footer .contact-wrap a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 992px) {
  footer .contact-wrap .email-icon-wrap {
    margin-right: 40px;
  }
}
@media (max-width: 991px) {
  footer .contact-wrap .email-icon-wrap {
    margin-bottom: 50px;
  }
}
footer .contact-wrap .email-icon-wrap a img {
  height: 100%;
  width: 100%;
  max-width: 66px;
}
@media (min-width: 992px) {
  footer .contact-wrap .email-icon-wrap a img {
    margin-right: 30px;
  }
}
@media (max-width: 1440px) {
  footer .contact-wrap .email-icon-wrap a img {
    width: 53px;
  }
}
footer .contact-wrap .email-icon-wrap a span {
  font-weight: 900;
}
@media (min-width: 992px) {
  footer .contact-wrap .phone-icon-wrap {
    margin-right: 40px;
  }
}
@media (max-width: 991px) {
  footer .contact-wrap .phone-icon-wrap {
    margin-bottom: 50px;
  }
}
footer .contact-wrap .phone-icon-wrap a img {
  width: 100%;
  height: 100%;
  max-width: 46px;
}
@media (min-width: 992px) {
  footer .contact-wrap .phone-icon-wrap a img {
    margin-right: 30px;
  }
}
@media (max-width: 1440px) {
  footer .contact-wrap .phone-icon-wrap a img {
    width: 37px;
  }
}
footer .contact-wrap .phone-icon-wrap a span {
  font-weight: 900;
}
footer .contact-wrap .linkedin-icon-wrap a {
  max-width: 76px;
  display: block;
}
@media (max-width: 1440px) {
  footer .contact-wrap .linkedin-icon-wrap a {
    width: 50px;
  }
}
footer .contact-wrap .linkedin-icon-wrap a img {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}
footer .download-resume-pdf {
  text-align: center;
}
footer .download-resume-pdf a {
  font-weight: 900;
  color: #000000;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  footer .download-resume-pdf a {
    font-size: 20px;
  }
}

.icon-wrap {
  max-width: 100px;
  text-align: center;
}
.icon-wrap .icon-name {
  font-weight: 900;
  font-size: 1.3rem;
  color: #848484;
}
.icon-wrap img {
  width: 100%;
}
